.pdf-container {
    overflow: auto;
    height: 800px; /* Set the desired height for the scrolling container. Default: 800px */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
}

.pdf-container canvas {
    margin: 0px; /* Add spacing between pages if desired */
}