@tailwind base;
@tailwind components;
@tailwind utilities;


.react-pdf__Page__canvas {
  @apply mx-auto;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  h1 {
    @apply text-2xl;
    @apply font-bold;
    @apply tracking-normal;
  }
  h2 {
    @apply text-xl;
    @apply font-bold;
    @apply tracking-normal;
  }
  h3 {
    @apply text-base;
    @apply font-bold;
    @apply tracking-normal;
  }
  h4 {
    @apply text-base;
    @apply font-bold;
    @apply tracking-normal;
    @apply uppercase;
    @apply text-[#586379];
  }
}

.Dropdown-control {
  @apply rounded-[4px] border-2 border-[#DFDFDF] bg-white px-4 py-2 flex gap-1 items-center transition-none;
}

.Dropdown-placeholder {
  @apply overflow-hidden text-ellipsis;
}

.Dropdown-control.Dropdown-disabled {
  @apply opacity-20 cursor-not-allowed;
}

.Dropdown-root.is-open .Dropdown-control {
  @apply rounded-b-none;
}

.Dropdown-arrow-wrapper {
  @apply w-3.5 text-[#5D5F69] mr-[-3px] ml-auto;
}

.Dropdown-menu {
  @apply absolute z-[1] w-full bg-white rounded-b-[4px] border-b-2 border-l-2 border-r-2 shadow-md;
}

.Dropdown-option {
  @apply py-3 px-4 hover:bg-[#ECF7FE];
}

.Dropdown-option.is-selected {
  @apply font-bold;
}

.Dropdown-option.is-disabled {
  @apply text-grey-1;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(100px);
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(100px);
}